<template>
  <section class="box-height perfromanceTable" style="padding: 0 15px">
    <div
      class="systemAnnouncement"
      style="background-color: #fff; padding-bottom: 30px"
    >
      <el-form
        :model="filter"
        ref="filter"
        style="background-color: #fff"
        label-width="120px"
      >
        <el-row>
          <div
            class="clearfix sechContent"
            style="padding: 15px 0px 0; display: flex"
          >
            <el-form-item label="发布时间：" prop="createdStartTime">
              <el-date-picker
                style="width: 400px"
                v-model="timeVal"
                value-format="yyyy-MM-dd HH:mm"
                format="yyyy-MM-dd HH:mm"
                @change="getTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="版本等级：" prop="level">
              <el-select
                v-model="filter.level"
                placeholder="请选择"
                style="width: 240px"
                clearable
                filterable
                @change="getData"
              >
                <el-option
                  v-for="(item, index) in level"
                  :key="'a_' + index"
                  :value="item.value"
                  :label="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="类型：" prop="type">
              <el-select
                v-model="filter.type"
                placeholder="请选择"
                style="width: 240px"
                clearable
                filterable
                @change="getData"
              >
                <el-option
                  v-for="(item, index) in Type"
                  :key="'a_' + index"
                  :value="item.value"
                  :label="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-col :span="24">
            <el-button
              type="primary"
              style="margin-left: 15px"
              size="mini"
              @click="getNew('')"
              >新增</el-button
            >
            <el-pagination
              :hide-on-single-page="filter.page.total > 10 ? false : true"
              v-if="filter.page.total > 0"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="filter.page.current"
              :page-size="filter.page.size"
              :pager-count="5"
              layout="total,prev, pager, next,sizes"
              :total="filter.page.total"
              :page-sizes="[10, 20, 50, 100]"
            ></el-pagination>
          </el-col>
        </el-row>
      </el-form>
      <div
        class="pd40"
        style="background-color: #fff; padding-top: 10px; padding-bottom: 20px"
      >
        <el-table
          :data="ListData"
          v-loading="listLoading"
          :header-cell-style="{
            'background-color': '#f5f7fa',
            'border-top': '1px #e5e5e5 solid',
          }"
          class="tabBorder custor"
          style="width: 100%; margin: 0px; border-top: 0"
        >
          <el-table-column prop="type" min-width="150" label="类型">
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="versionCode"
            min-width="200"
            label="版本号"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.versionCode ? scope.row.versionCode : "-"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="versionLevel"
            min-width="150"
            label="版本等级"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.versionLevel ? scope.row.versionLevel : "-"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="title"
            min-width="150"
            label="标题名称"
          >
          </el-table-column>
          <el-table-column
            prop="versionCreateBy"
            min-width="150"
            label="创建人"
          >
          </el-table-column>
          <el-table-column
            prop="versionReleaseTime"
            min-width="150"
            label="系统发布时间"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.versionReleaseTime
                  ? scope.row.versionReleaseTime
                  : "-"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="announcementSendTime"
            label="通知发送时间"
            min-width="150"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.announcementSendTime
                  ? scope.row.announcementSendTime
                  : "-"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="versionCreateTime"
            label="创建时间"
            min-width="150"
          >
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="left" width="180">
            <template slot-scope="scope">
              <span class="tabHref" size="mini" @click="getNew(scope.row.id)"
                >编辑
              </span>
              <el-divider direction="vertical"></el-divider>
              <span class="tabHref" size="mini" @click="del(scope.row.id)"
                >删除</span
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :hide-on-single-page="filter.page.total > 10 ? false : true"
          v-if="filter.page.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="filter.page.current"
          :page-size="filter.page.size"
          :pager-count="5"
          layout="total,prev, pager, next,sizes"
          :total="filter.page.total"
          :page-sizes="[10, 20, 50, 100]"
          style="padding: 10px 15px 20px"
        ></el-pagination>
      </div>
    </div>
    <!-- 抽屉弹窗 -->
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">
        {{ title }}
      </div>
      <div class="drawerCon">
        <addNew
          v-if="drawerVisible"
          ref="addNew"
          :ggid="id"
          @list="loadInfolist()"
          @close="handleClose()"
        ></addNew>
        <div class="drawerCon_footer fixedFoot">
          <el-button type="default" plain size="mini" @click="handlePreview()"
            >预览</el-button
          >
          <el-button type="default" plain size="mini" @click="handleClose()"
            >取消</el-button
          >

          <el-button
            type="primary"
            size="mini"
            :loading="saveloading"
            @click="saveInfos"
            style="margin-right: 15px"
            >保存</el-button
          >
        </div>
      </div>
    </el-drawer>
  </section>
</template>

<script>
import addNew from "../../components/set/addOredit.vue";
import {
  announcementPage, //查询公告及通知列表
  announcementDeleteVersion, //删除系统版本更新公告
} from "../../api/api";
export default {
  components: {
    addNew,
  },
  data() {
    return {
      filter: {
        level: "",
        type: "",
        page: {
          total: 0,
          current: 1,
          size: 50,
        },
        queryTimeStart: "",
        queryTimeEnd: "",
      },
      timeVal: null,
      ListData: [],
      listLoading: false,
      level: [
        {
          name: "一级版本",
          value: "一级版本",
        },
        {
          name: "二级版本",
          value: "二级版本",
        },
        {
          name: "三级版本",
          value: "三级版本",
        },
        {
          name: "全部",
          value: "",
        },
      ],
      Type: [
        {
          name: "系统更新",
          value: "系统更新",
        },
        {
          name: "系统公告",
          value: "系统公告",
        },
        {
          name: "自定义通知",
          value: "自定义通知",
        },
        {
          name: "全部",
          value: "",
        },
      ],
      drawerVisible: false,
      saveloading: false,
      title: "新增",
      id: "",
    };
  },
  created() {
    this.loadInfolist();
  },
  methods: {
    //选择时间
    getTime(val) {
      this.timeVal = val;
      this.loadInfolist();
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.filter.page.size = val;
      this.filter.page.current = 1;
      this.loadInfolist();
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.filter.page.current = val;
      this.loadInfolist();
    },
    getData() {
      this.filter.page.current = 1;
      this.loadInfolist();
    },
    //获取列表数据
    loadInfolist() {
      window.scrollTo(0, 0);
      this.listLoading = true;
      this.filter.queryTimeStart = this.timeVal ? this.timeVal[0] : "";
      this.filter.queryTimeEnd = this.timeVal ? this.timeVal[1] : "";
      announcementPage(this.filter).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          this.filter.page.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    //新增
    getNew(id) {
      if (id) {
        this.title = "编辑";
        this.id = id;
      } else {
        this.id = null;
        this.title = "新增";
      }
      this.drawerVisible = true;
    },
    // 关闭
    handleClose() {
      this.$refs.addNew.cleanFrom("from");
      this.drawerVisible = false;
    },
    //保存
    saveInfos() {
      this.$refs.addNew.submitForm("from");
    },
    //预览
    handlePreview() {
      this.$refs.addNew.showPreview();
    },
    //删除
    del(id) {
      this.$confirm("是否删除该项?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          announcementDeleteVersion({ id: id }).then((res) => {
            if (res.success) {
              this.getData();
              this.$message.success("删除成功！");
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
  },
};
</script>