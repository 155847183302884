<template>
  <section style="margin: 15px 20px">
    <div class="header">
      <h3>
        {{ dataDetail.versionCode }}
        <el-divider
          v-if="dataDetail.versionCode && dataDetail.title"
          direction="vertical"
        ></el-divider
        >{{ dataDetail.title }}
        <span v-if="dataDetail.versionLevel"
          >({{ dataDetail.versionLevel }})</span
        >
      </h3>
      <p
        style="color: #999; margin-left: 20px"
        v-if="dataDetail.announcementSendTime"
      >
        系统发布时间：{{ dataDetail.announcementSendTime }}
      </p>
    </div>
    <el-divider></el-divider>
    <div v-html="dataDetail.content" style="width: 100%;overflow: auto;line-height: 30px;">{{ dataDetail.content }}</div>
  </section>
</template>

<script>
export default {
  props: ["dataDetail"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  h3 {
    margin: 0;
    flex: 1;
  }
}
</style>