<template>
  <section style="background-color: #fff">
    <div class="drawerCon_from" style="border: 0; padding-right: 55px">
      <el-form
        @submit.native.prevent
        :model="from"
        ref="from"
        :rules="rules"
        label-width="130px"
        class="addForm"
      >
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="类型：" prop="type">
              <el-radio-group v-model="from.type" @change="typeChange">
                <el-radio label="系统更新">系统更新</el-radio>
                <el-radio label="系统公告">系统公告</el-radio>
                <el-radio label="自定义通知">自定义通知</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24" v-if="from.type == '系统更新'">
          <el-col :span="8">
            <el-form-item label="版本号：" prop="versionCode">
              <el-input
                v-model.trim="from.versionCode"
                placeholder="最多不超过20个字"
                maxlength="20"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="版本等级：" prop="versionLevel">
              <el-select
                v-model="from.versionLevel"
                placeholder="请选择"
                clearable
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in level"
                  :key="'a_' + index"
                  :value="item.value"
                  :label="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="标题名称：" prop="title">
              <el-input
                v-model.trim="from.title"
                placeholder="最多不超过100个字"
                maxlength="100"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="from.type == '系统更新'">
            <el-form-item label="系统发布时间：" prop="versionReleaseTime">
              <el-date-picker
                v-model="from.versionReleaseTime"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm"
                format="yyyy-MM-dd HH:mm"
                placeholder="系统发布时间"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="from.type == '自定义通知'">
            <el-form-item
              label="发送对象："
              prop="receivers"
              class="is-required"
            >
              <el-cascader
                style="width: 100%"
                filterable
                clearable
                :options="options2"
                collapse-tags
                :show-all-levels="false"
                v-model="from.receivers"
                :props="props"
                placeholder="请选择发送对象"
                ref="dataRef"
              >
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="通知发送时间："
              prop="announcementSendTime"
              :rules="[
                {
                  required: from.type == '自定义通知',
                  trigger: 'change',
                  message: '请选择通知发送时间',
                },
              ]"
            >
              <el-date-picker
                v-model="from.announcementSendTime"
                value-format="yyyy-MM-dd HH:mm"
                format="yyyy-MM-dd HH:mm"
                type="datetime"
                placeholder="通知发送时间"
                style="width: 100%"
                :picker-options="endDateOpt"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-form-item label="内容：" prop="content" class="is-required">
            <tinymce
              v-model="from.content"
              :height="450"
              @annexList="annexList"
              :ishowImage="true"
              ref="removeAnList"
              style="width: calc(100% - 22px); margin: 0 10px"
            />
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <div v-if="customizationMsgIsShow" class="baberrageWrape">
      <div class="happyForm" style="padding-bottom: 0">
        <img
          class="noOverbg"
          src="@/assets/Customizationbg.png"
          alt=""
          style="display: block; border-radius: 10px"
        />
        <div class="customizationContent">
          <i class="el-icon-circle-close" @click="closeCustomizationMsg"></i>
          <div class="details">
            <p class="title">{{ customizationMsg.title }}</p>
            <div
              v-html="customizationMsg.content"
              style="line-height: 22px"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      :visible.sync="dialogVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">{{ titName }}</div>
      <div class="drawerCon">
        <sysMessage
          v-if="dialogVisible"
          ref="sysMessage"
          :dataDetail="dataDetail"
        ></sysMessage>
      </div>
    </el-drawer>
  </section>
</template>
<script>
import Tinymce from "@/components/Tinymce";
import sysMessage from "@/components/sysMessage.vue";
import {
  announcementUpdate, //更新系统版本更新公告
  announcementAddVersion, //添加系统版本更新公告
  announcementAddAnnouncement, //添加系统版本
  announcementAddCustom, //添加自定义通知
  getAnnouncementDetail, //根据id查询公告详情
  permissionTree, //对象
} from "../../api/api";
export default {
  components: {
    Tinymce,
    sysMessage,
  },
  props: ["ggid"],
  data() {
    return {
      from: {
        id: "",
        type: "系统更新",
        receivers: [],
        announcementSendTime: null,
        title: null,
        content: null,
      },
      rules: {
        type: [{ required: true, message: "请选择", trigger: "change" }],
        versionCode: [
          {
            required: true,
            trigger: "blur",
            message: "请输入版本号",
          },
        ],
        versionLevel: [
          { required: true, message: "请选择版本等级", trigger: "change" },
        ],
        title: [
          {
            required: true,
            trigger: "blur",
            message: "请输入标题名称",
          },
        ],
        versionReleaseTime: [
          { required: true, message: "请选择系统发布时间", trigger: "change" },
        ],
        receivers: [
          { required: true, message: "请选择发送对象", trigger: "change" },
        ],
        // announcementSendTime: [
        //   {
        //     required: this.required,
        //     message: "请选择通知发送时间",
        //     trigger: "change",
        //   },
        // ],
        // content: [
        //   {
        //     required: true,
        //     trigger: "change",
        //     message: "请输入内容",
        //   },
        // ],
      },
      rules1: {
        announcementSendTime: [
          { required: true, message: "请选择通知发送时间", trigger: "change" },
        ],
      },
      isClear: false,
      detail: "",
      phoneEditor: "",
      level: [
        {
          name: "一级版本",
          value: "一级版本",
        },
        {
          name: "二级版本",
          value: "二级版本",
        },
        {
          name: "三级版本",
          value: "三级版本",
        },
      ],
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
      },
      options2: [],
      searchIdList: [],
      endDateOpt: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      required: false,
      titName: "",
      dataDetail: {},
      dialogVisible: false,
      customizationMsgIsShow: false,
      customizationMsg: {},
    };
  },
  watch: {
    ggid(newValue, oldValue) {
      this.getDetail(newValue);
    },
  },
  created() {
    if (this.ggid) {
      this.getDetail(this.ggid);
    } else {
      this.cleanFrom("from");
    }
    this.permissionTree();
  },
  methods: {
    annexList(arr) {
      this.annexListdata = arr;
    },
    // 清空
    cleanFrom(formName) {
      // this.from.type = "系统更新";
      // this.$refs[formName].resetFields();
      // this.$refs.removeAnList.handleAnRemove(this.annexListdata);
      this.from = {
        id: null,
        type: "系统更新",
        content: "",
      };
    },
    getDetail(id) {
      getAnnouncementDetail({ id: id }).then((res) => {
        if (res.success) {
          this.from = res.result;
        }
      });
    },
    getSearch(val) {
      this.from.receivers = this.searchIdList.map((i) => {
        return i[i.length - 1];
      });
    },
    //回显(多级)
    changeDetSelect(key, treeData) {
      let arr = []; // 在递归时操作的数组
      let returnArr = []; // 存放结果的数组
      let depth = 0; // 定义全局层级
      // 定义递归函数
      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN; // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id;
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
            break;
          } else {
            if (childrenData[j].list && childrenData[j].list.length > 0) {
              depth++;
              childrenEach(childrenData[j].list, depth);
            }
          }
        }
        return returnArr;
      }
      return childrenEach(treeData, depth);
    },
    submitForm(formName) {
      return new Promise((resole) => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.from.content == "" || this.from.content == null) {
              this.$message.error("请输入内容");
              return false;
            }
            if (this.from.type == "系统公告") {
              this.from.versionCode = null;
              this.from.versionLevel = null;
              this.from.versionReleaseTime = null;
            }
            let announcement = null;
            if (this.ggid) {
              this.from.id = this.ggid;
              announcement = announcementUpdate;
            } else {
              if (this.from.type == "系统更新") {
                announcement = announcementAddVersion;
              } else if (this.from.type == "系统公告") {
                announcement = announcementAddAnnouncement;
              } else {
                announcement = announcementAddCustom;
              }
            }
            announcement(this.from).then((res) => {
              if (res.success) {
                this.$message.success("成功");
                this.cleanFrom("from");
                this.$emit("list");
                this.$emit("close");
              }
              resole();
            });
          }
        });
      }).catch((error) => {});
    },
    // 小类
    permissionTree() {
      permissionTree({ type: "顾问" }).then((res) => {
        if (res.success) {
          this.options2 = res.result;
        }
      });
    },
    //类型切换
    typeChange(val) {
      if (val == "自定义通知") {
        this.required = true;
      } else {
        this.required = false;
      }
      this.resetFrom(val);
    },
    showPreview() {
      this.titName = this.from.type;
      if (this.from.type == "系统公告" || this.from.type == "系统更新") {
        this.dataDetail = this.from;
        this.dialogVisible = true;
      } else if (this.from.type == "自定义通知") {
        //  this.customizationMsgId = res.relativeId;
        this.customizationMsg.title = this.from.title;
        this.customizationMsg.content = this.from.content;
        this.customizationMsgIsShow = true;
      }
    },
    //关闭弹窗
    handleClose(done) {
      done();
      this.dialogVisible = false;
      // this.cancel();
    },
    closeCustomizationMsg() {
      this.customizationMsg = {};
      this.customizationMsgIsShow = false;
    },
    resetFrom(val) {
      this.from = {
        id: "",
        type: val,
        receivers: [],
        announcementSendTime: null,
        title: null,
        content: null,
      };
      this.$refs.removeAnList.setContent("");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/css/happyNews.less";
.baberrageWrape {
  z-index: 9999;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}
.customizationContent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 170px 40px 30px;
  .title {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    color: #333333;
    margin-bottom: 20px;
  }
  .details {
    height: 300px;
    overflow-y: auto;
    overflow-wrap: anywhere;
    width: 100%;
  }
  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  *:hover::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px #ededed;
    background: #ededed;
  }
  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #fff;
  }
}
</style>